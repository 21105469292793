import React from 'react';

import HeritageText from '../components/HeritageText.jsx';
import HeritageTextImage from './HeritageTextImage.jsx';

const Heritage = () => {
    return (
       <>
       <HeritageText />
       <HeritageTextImage />
       </>
    );
};

export default Heritage;
